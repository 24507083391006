const menu = {
  menuAll: [
    {
      path: 'dataCenter',
      icon: 'el-icon-pie-chart',
      label: '数据中心',
      name: 'DataCenter'
    }, {
      path: 'elderlyDataCenter',
      icon: 'el-icon-pie-chart',
      label: '数据报告',
      name: 'elderlyDataCenter'
    },
    {
      path: 'personManage',
      icon: 'el-icon-s-help',
      label: '长者档案',
      name: 'PersonManage'
    },
    {
      path: 'workPerson',
      icon: 'el-icon-user-solid',
      label: '从业人员',
      name: 'WorkPerson'
    },
    {
      path: 'serveRecords',
      icon: 'el-icon-s-order',
      label: '服务记录',
      name: 'ServeRecords'
    },
    {
      path: 'visitingStatistics',
      icon: 'el-icon-s-order',
      label: '探访统计',
      name: 'visitingStatistics'
    },
    {
      path: 'serviceStatistics',
      icon: 'el-icon-s-order',
      label: '数据统计',
      name: 'serviceStatistics'
    },
    {
      path: 'caringPlatform',
      icon: 'el-icon-s-order',
      label: '关爱平台',
      name: 'caringPlatform'
    },
    {
      path: 'smartCaringPlatform',
      icon: 'el-icon-s-order',
      label: '智能关爱平台',
      name: 'smartCaringPlatform'
    },
    {
      path: 'serveOrgs',
      icon: 'el-icon-place',
      name: 'ServeOrgs',
      label: '服务企业'
      //   children: [{
      //     path: 'serveOrgs',
      //     icon: 'el-icon-office-building',
      //     label: '服务企业',
      //     name: 'ServeOrgs',
      //   }
      // ],
    },
    {
      path: 'nursingHome/mechaismManage/sumCenter',
      icon: 'el-icon-sunny',
      label: '日间照料中心',
      name: 'SumCenter'
    },
    {
      path: 'smartElderlyPlat',
      icon: 'fa fa-book',
      label: '智慧养老床位管理平台',
      name: 'SmartElderlyPlat',
      children: [
        {
          path: 'dataFlash',
          icon: '',
          label: '数据快报',
          name: 'DataFlash'
        },
        {
          path: 'smartElderly',
          icon: '',
          label: '智慧养老床位',
          name: 'SmartElderly'
        }
      ]
    },
    {
      path: 'assessManage/personAssess',
      icon: 'el-icon-office-building',
      label: '人员评估',
      name: 'PersonAssess'
    },
    {
      path: 'assessManage/personRecord',
      icon: 'el-icon-office-building',
      label: '养老顾问服务报告',
      name: 'PersonRecord'
    },
    {
      path: 'oldChange',
      icon: 'el-icon-office-building',
      label: '适老化改造服务记录',
      name: 'OldChange'
    },
    {
      path: 'oldChangeProject',
      icon: 'el-icon-office-building',
      label: '适老化改造项目清单',
      name: 'oldChangeProject'
    },
    {
      path: 'oldChangeProduct',
      icon: 'el-icon-office-building',
      label: '适老化改造产品清单',
      name: 'oldChangeProduct'
    },
    {
      path: 'oldChangeExamine',
      icon: 'el-icon-guide',
      label: '适老化审核管理',
      name: 'oldChangeExamine',
      children: [
        {
          path: 'examine/audit',
          icon: 'el-icon-guide',
          label: '待审核',
          name: 'oldChangeExamineAudit'
        },
        {
          path: 'examine/audited',
          icon: 'el-icon-s-flag',
          label: '已审核',
          name: 'oldChangeExamineAudited'
        }
      ]
    },
    // {
    //   path: 'assessManage',
    //   icon: 'el-icon-place',
    //   name: 'AssessManage',
    //   label: '评估管理',
    //   children: [{
    //     path: 'assessManage/personAssess',
    //     icon: 'el-icon-office-building',
    //     label: '人员评估',
    //     name: 'PersonAssess',
    //   },
    //   {
    //     path: 'assessManage/personRecord',
    //     icon: 'el-icon-office-building',
    //     label: '养老顾问服务报告',
    //     name: 'PersonRecord',
    //   },
    // ],
    // },
    // {
    //   path: 'nursingHome/medicalInst',
    //   icon: 'el-icon-suitcase',
    //   label: '社区医疗机构',
    //   name: 'MedicalInst',
    // },
    // {
    //   path: 'nursingHome',
    //   icon: 'fa fa-book',
    //   name: 'NursingHome',
    //   label: '社区养老',
    //   children: [{
    //       path: 'nursingHome/dataCenter',
    //       icon: 'el-icon-location',
    //       label: '数据中心',
    //       name: 'NursingHomeDataCenter',
    //     },
    //     {
    //       path: 'nursingHome/mechaismManage/generalServiceCenter',
    //       icon: 'el-icon-location',
    //       label: '综合养老服务中心',
    //       name: 'GeneralServiceCenter',
    //     },
    //     {
    //       path: 'nursingHome/mechaismManage/sumCenter',
    //       icon: 'el-icon-location',
    //       label: '日间照料中心',
    //       name: 'SumCenter',
    //     },
    //     {
    //       path: 'nursingHome/mechaismManage/serviceStation',
    //       icon: 'el-icon-location',
    //       label: '居家养老服务站',
    //       name: 'ServiceStation',
    //     },
    //     {
    //       path: 'nursingHome/medicalInst',
    //       icon: 'el-icon-location',
    //       label: '社区医疗机构',
    //       name: 'MedicalInst',
    //     },{
    //       path: 'happyHouse',
    //       icon: 'el-icon-location',
    //       label: '农村幸福院',
    //       name: 'HappyHouse',
    //     }
    //   ],
    // },
    // {
    //   path: 'agedEat',
    //   icon: 'fa fa-book',
    //   name: 'AgedEat',
    //   label: '老年餐',
    //   children: [{
    //       path: 'agedEat/mechaismManage',
    //       icon: 'el-icon-location',
    //       label: '机构管理',
    //       name: 'AgedEatMechaismManage',
    //     },
    //     {
    //       path: 'agedEat/workPerson',
    //       icon: 'el-icon-location',
    //       label: '运营日报',
    //       name: 'OperateDailys',
    //     },
    //     {
    //       path: 'agedEat/dataCenter',
    //       icon: 'el-icon-location',
    //       label: '数据中心',
    //       name: 'AgedEatDataCenter',
    //     },
    //     // {
    //     //   path: 'agedEat/manManage/manManage',
    //     //   icon: 'el-icon-location',
    //     //   label: '从业人员',
    //     //   name: 'ManManage',
    //     // },
    //     {
    //       path: 'agedEat/manManage/MealRecord',
    //       icon: 'el-icon-location',
    //       label: '用餐记录111111',
    //       name: 'OperateDaily',
    //     },
    //     {
    //       path: 'agedEat/mealCard',
    //       icon: 'el-icon-location',
    //       label: '饭卡管理',
    //       name: 'MealCard',
    //     }
    //   ],
    // },
    // {
    //   path: 'homeCareBeds',
    //   icon: 'fa fa-book',
    //   name: 'HomeCareBeds',
    //   label: '家庭养老床位11',
    //   children: [{
    //       path: 'homeCareBeds/bedCompany',
    //       icon: 'el-icon-location',
    //       label: '施工单位',
    //       name: 'BedCompany',
    //     },
    //     {
    //       path: 'homeCareBeds/bedPerson',
    //       icon: 'el-icon-location',
    //       label: '人员信息',
    //       name: 'BedPerson',
    //     },
    //   ],
    // },
    // {
    //   path: 'agedCounselor',
    //   icon: 'fa fa-book',
    //   name: 'AgedCounselor',
    //   label: '养老顾问',
    //   children: [{
    //       path: 'agedCounselor/personnelManage/personnelManage',
    //       icon: 'el-icon-location',
    //       label: '人员管理',
    //       name: 'AgedCounselorPersonnelManage',
    //     },
    //     {
    //       path: 'agedCounselor/serveRecord',
    //       icon: 'el-icon-location',
    //       label: '服务记录',
    //       name: 'AgedCounselorServeRecord',
    //     },
    //   ],
    // },
    // {
    //   path: 'agedMarket',
    //   icon: 'fa fa-book',
    //   name: 'AgedMarket',
    //   label: '养老早市',
    //   children: [{
    //       path: 'agedMarket/agedMarketData',
    //       icon: 'el-icon-location',
    //       label: '数据中心',
    //       name: 'AgedMarketPersonnelManage',
    //     },
    //     {
    //       path: 'agedMarket/agedMarketList',
    //       icon: 'el-icon-location',
    //       label: '机构列表',
    //       name: 'AgedMarketServeList',
    //     },
    //   ],
    // },
    {
      path: 'nursingHome/nursingHome',
      icon: 'el-icon-office-building',
      label: '养老院管理',
      name: 'NursingHomeNursingHome'
    },
    {
      path: 'task',
      icon: 'el-icon-guide',
      label: '任务管理',
      name: 'Task',
      children: [
        {
          path: 'task/task',
          icon: 'el-icon-guide',
          label: '任务管理',
          name: 'TaskTask'
        },
        {
          path: 'task/activity',
          icon: 'el-icon-s-flag',
          label: '活动管理',
          name: 'TaskActivity'
        }
      ]
    },
    {
      path: 'news/news',
      icon: 'el-icon-news',
      label: '新闻管理',
      name: 'NewsNews'
    },
    {
      path: 'goods',
      icon: 'el-icon-s-shop',
      label: '积分商城管理',
      name: 'Goods',
      children: [
        {
          path: 'goods/goods',
          icon: 'el-icon-goods',
          label: '商品管理',
          name: 'GoodsGoods'
        },
        {
          path: 'goods/exchange',
          icon: 'el-icon-box',
          label: '兑换管理',
          name: 'GoodsExchange'
        }
      ]
    },
    {
      path: 'neighborhood',
      icon: 'el-icon-place',
      label: '邻里圈管理',
      name: 'Neighborhood',
      children: [
        {
          path: 'neighborhood/topics',
          icon: 'el-icon-mic',
          label: '话题',
          name: 'NeighborhoodTopics'
        },
        {
          path: 'neighborhood/neighborhood',
          icon: 'el-icon-house',
          label: '邻里圈',
          name: 'NeighborhoodNeighborhood'
        }
      ]
    },
    {
      path: 'feedback/feedback',
      icon: 'el-icon-s-comment',
      label: '服务直通车',
      name: 'FeedbackFeedback'
    },
    {
      path: 'volunteer',
      icon: 'el-icon-s-check',
      label: '志愿者管理',
      name: 'Volunteer',
      children: [
        {
          path: 'volunteer/volunteer',
          icon: 'el-icon-s-custom',
          label: '志愿者',
          name: 'VolunteerVolunteer'
        },
        {
          path: 'volunteer/team',
          icon: 'el-icon-office-building',
          label: '志愿者小组',
          name: 'VolunteerTeam'
        }
      ]
    },
    {
      path: 'party',
      icon: 'el-icon-s-help',
      label: '党群管理',
      name: 'Party',
      children: [
        {
          path: 'party/organization',
          icon: 'el-icon-help',
          label: '党组织',
          name: 'PartyOrganization'
        },
        {
          path: 'party/party',
          icon: 'el-icon-s-custom',
          label: '党员',
          name: 'PartyParty'
        }
      ]
    },
    {
      path: 'config',
      icon: 'el-icon-s-tools',
      label: '配置',
      name: 'Config',
      children: [
        {
          path: 'config/miniappMenu',
          icon: 'el-icon-menu',
          label: '小程序导航',
          name: 'ConfigMiniappMenu'
        },
        {
          path: 'config/miniappBanner',
          icon: 'el-icon-monitor',
          label: '小程序Banner',
          name: 'ConfigMiniappBanner'
        }
      ]
    },
    {
      path: 'audit',
      icon: 'el-icon-collection',
      label: '审核管理',
      name: 'Audit',
      children: [
        {
          path: 'audit/nursingHome',
          icon: 'el-icon-office-building',
          label: '养老院',
          name: 'AuditNursingHome'
        },
        {
          path: 'audit/sumCenter',
          icon: 'el-icon-sunny',
          label: '日间照料中心',
          name: 'AuditSumCenter'
        },
        {
          path: 'audit/seniorMeal',
          icon: 'el-icon-office-building',
          label: '老年餐机构',
          name: 'AuditSeniorMeal'
        },
        {
          path: 'audit/neighborhood',
          icon: 'el-icon-place',
          label: '邻里圈',
          name: 'AuditNeighborhood'
        },
        {
          path: 'audit/enterprise',
          icon: 'el-icon-office-building',
          label: '服务企业',
          name: 'AuditEnterprise'
        },
        {
          path: 'audit/realName',
          icon: 'el-icon-s-custom',
          label: '实名认证',
          name: 'AuditRealName'
        },
        {
          path: 'audit/volunteer',
          icon: 'el-icon-s-custom',
          label: '志愿者',
          name: 'AuditVolunteer'
        },
        {
          path: 'audit/party',
          icon: 'el-icon-s-custom',
          label: '党员',
          name: 'AuditParty'
        }
      ]
    },
    {
      path: 'device/device',
      icon: 'el-icon-s-tools',
      label: '设备管理',
      name: 'DeviceDevice'
    }
  ],
  // menuOne: [
  //   {
  //     path: 'nursingHome',
  //     icon: 'fa fa-book',
  //     name: 'NursingHome',
  //     label: '社区养老',
  //     children: [
  //       {
  //         path: 'nursingHome/dataCenter',
  //         icon: 'el-icon-location',
  //         label: '数据中心',
  //         name: 'NursingHomeDataCenter'
  //       },
  //       {
  //         path: 'nursingHome/mechaismManage/generalServiceCenter',
  //         icon: 'el-icon-location',
  //         label: '综合养老服务中心',
  //         name: 'GeneralServiceCenter'
  //       },
  //       {
  //         path: 'nursingHome/mechaismManage/sumCenter',
  //         icon: 'el-icon-location',
  //         label: '日间照料中心',
  //         name: 'SumCenter'
  //       },
  //       {
  //         path: 'nursingHome/mechaismManage/serviceStation',
  //         icon: 'el-icon-location',
  //         label: '居家养老服务站',
  //         name: 'ServiceStation'
  //       },
  //       {
  //         path: 'nursingHome/medicalInst',
  //         icon: 'el-icon-location',
  //         label: '社区医疗机构',
  //         name: 'MedicalInst'
  //       }
  //     ]
  //   }
  // ],
  menuOne: [
    {
      path: 'nursingHome/mechaismManage/sumCenter',
      icon: 'el-icon-sunny',
      label: '日间照料中心',
      name: 'SumCenter'
      // children: [{
      //     path: 'nursingHome/dataCenter',
      //     icon: 'el-icon-location',
      //     label: '数据中心',
      //     name: 'NursingHomeDataCenter',
      //   },
      //   {
      //     path: 'nursingHome/mechaismManage/generalServiceCenter',
      //     icon: 'el-icon-location',
      //     label: '综合养老服务中心',
      //     name: 'GeneralServiceCenter',
      //   },
      //   {
      //     path: 'nursingHome/mechaismManage/sumCenter',
      //     icon: 'el-icon-location',
      //     label: '日间照料中心',
      //     name: 'SumCenter',
      //   },
      //   {
      //     path: 'nursingHome/mechaismManage/serviceStation',
      //     icon: 'el-icon-location',
      //     label: '居家养老服务站',
      //     name: 'ServiceStation',
      //   },
      //   {
      //     path: 'nursingHome/medicalInst',
      //     icon: 'el-icon-location',
      //     label: '社区医疗机构',
      //     name: 'MedicalInst',
      //   }
      // ],
    }
  ],
  menuTwo: [
    {
      path: 'agedEat',
      icon: 'fa fa-book',
      name: 'AgedEat',
      label: '老年餐',
      children: [
        {
          path: 'agedEat/mechaismManage',
          icon: 'el-icon-location',
          label: '机构管理',
          name: 'AgedEatMechaismManage'
        },
        // {
        //   path: 'agedEat/workPerson',
        //   icon: 'el-icon-location',
        //   label: '运营日报',
        //   name: 'OperateDailys',
        // },
        // {
        //   path: 'agedEat/dataCenter',
        //   icon: 'el-icon-location',
        //   label: '数据中心',
        //   name: 'AgedEatDataCenter',
        // },
        {
          path: 'agedEat/manManage/manManage',
          icon: 'el-icon-location',
          label: '从业人员',
          name: 'ManManage'
        },
        {
          path: 'agedEat/manManage/MealRecord',
          icon: 'el-icon-location',
          label: '用餐记录',
          name: 'OperateDaily'
        },
        {
          path: 'agedEat/mealCard',
          icon: 'el-icon-location',
          label: '饭卡管理',
          name: 'MealCard'
        }
      ]
    }
  ],
  // menuThree: [
  //   {
  //     path: 'adaptationToAging',
  //     icon: 'fa fa-book',
  //     name: 'AdaptationToAging',
  //     label: '适老化改造',
  //     children: [
  //       {
  //         path: 'adaptationToAging/chgCompany',
  //         icon: 'el-icon-location',
  //         label: '施工单位',
  //         name: 'ChgCompany'
  //       },
  //       {
  //         path: 'adaptationToAging/chgPerson',
  //         icon: 'el-icon-location',
  //         label: '人员信息',
  //         name: 'ChgPerson'
  //       }
  //     ]
  //   }
  // ],
  // menuFour: [
  //   {
  //     path: 'homeCareBeds',
  //     icon: 'fa fa-book',
  //     name: 'HomeCareBeds',
  //     label: '家庭养老床位',
  //     children: [
  //       {
  //         path: 'homeCareBeds/bedCompany',
  //         icon: 'el-icon-location',
  //         label: '施工单位',
  //         name: 'BedCompany'
  //       },
  //       {
  //         path: 'homeCareBeds/bedPerson',
  //         icon: 'el-icon-location',
  //         label: '人员信息',
  //         name: 'BedPerson'
  //       }
  //     ]
  //   }
  // ],
  // menuFive: [
  //   {
  //     path: 'homeCare',
  //     icon: 'fa fa-book',
  //     name: 'HomeCare',
  //     label: '居家养老',
  //     children: [
  //       {
  //         path: 'serveOrgs',
  //         icon: 'el-icon-office-building',
  //         label: '服务企业',
  //         name: 'ServeOrgs'
  //       }
  //     ]
  //   }
  // ],
  menuSix: [
    {
      path: 'nursingHome/callCenter',
      icon: 'el-icon-mic',
      label: '呼叫中心',
      name: 'CallCenter',
      children: [
        {
          path: 'callRecords',
          icon: 'el-icon-office-building',
          label: '通话记录',
          name: 'CallRecords'
        },
        {
          path: 'serviceCall',
          icon: 'el-icon-office-building',
          label: '服务记录',
          name: 'ServiceCall'
        },
        {
          path: 'missCall',
          icon: 'el-icon-office-building',
          label: '未接电话记录',
          name: 'MissCall'
        },
        {
          path: 'callTable',
          icon: 'el-icon-office-building',
          label: '呼叫报表',
          name: 'CallTable'
        }
      ]
    }
  ],
  // menuSeven: [
  //   {
  //     path: 'mechanismCare',
  //     icon: 'fa fa-book',
  //     name: 'MechanismCare',
  //     label: '机构养老',
  //     children: [
  //       {
  //         path: 'mechanismCare/datacenter',
  //         icon: 'el-icon-location',
  //         label: '数据中心',
  //         name: 'MechanismCareDataCenter'
  //       },
  //       {
  //         path: 'mechanismCare/personManage',
  //         icon: 'el-icon-location',
  //         label: '机构管理',
  //         name: 'PersonsManage'
  //       },
  //       {
  //         path: 'mechanismCare/bed',
  //         icon: 'el-icon-location',
  //         label: '床位管理',
  //         name: 'BedInfo'
  //       },
  //       {
  //         path: 'mechanismCare/entry',
  //         icon: 'el-icon-location',
  //         label: '办理入住',
  //         name: 'EntryInfo'
  //       }
  //     ]
  //   }
  // ],
  // menuEight: [
  //   {
  //     path: 'agedEat/evaluationAgency',
  //     icon: 'el-icon-office-building',
  //     label: '评估机构',
  //     name: 'EvaluationAgency'
  //   }
  // ],
  menuNine: [
    {
      path: 'system',
      icon: 'fa fa-book',
      name: 'System',
      label: '系统管理',
      children: [
        {
          path: 'globalEvn',
          icon: 'el-icon-location',
          label: '抬头管理',
          name: 'GlobalEvn'
        },
        {
          path: 'dictType',
          icon: 'el-icon-location',
          label: '字典类型',
          name: 'DictType'
        },
        {
          path: 'dict',
          icon: 'el-icon-location',
          label: '数据字典',
          name: 'Dict'
        },
        {
          path: 'menu',
          icon: 'el-icon-location',
          label: '系统菜单',
          name: 'Menu'
        },
        {
          path: 'userManage',
          icon: 'el-icon-location',
          label: '用户管理',
          name: 'UserManage'
        },
        {
          path: 'community',
          icon: 'el-icon-location',
          label: '社区管理',
          name: 'Community'
        },
        {
          path: 'uploadFile',
          icon: 'el-icon-location',
          label: '文件管理',
          name: 'UploadFile'
        },
        {
          path: 'log',
          icon: 'el-icon-location',
          label: '访问日志',
          name: 'LogManage'
        },
        {
          path: 'system/auth',
          icon: 'el-icon-s-help',
          label: '权限节点',
          name: 'SystemAuth'
        },
        {
          path: 'system/role',
          icon: 'el-icon-s-cooperation',
          label: '系统角色',
          name: 'SystemRole'
        }
      ]
    }
  ],
  menuTen: [
    {
      path: 'volunteer/volunteerInfo',
      icon: 'el-icon-mic',
      label: '志愿者管理',
      name: 'volunteerInfo',
      children: [
        {
          path: 'volunteerInfo',
          icon: 'el-icon-office-building',
          label: '志愿者档案',
          name: 'volunteerInfo'
        },
        {
          path: 'volunteerAct',
          icon: 'el-icon-office-building',
          label: '志愿者活动',
          name: 'volunteerAct'
        }
      ]
    }
  ]
}

function getChildName(key) {
  return new Promise(resolve => {
    const data = []
    try {
      const func = array => {
        array.forEach(item => {
          if ('children' in item && item.children && item.children.length) {
            func(item.children)
          } else {
            data.push(item.name)
          }
        })
      }
      func(menu[key])
    } catch (e) {
      console.log(e)
    }
    resolve(data)
  })
}

export { menu, getChildName }
