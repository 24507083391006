<template>
  <div class="wrapper">
    <div class="side-title">
      <!-- <img src="@/assets/images/minZhengLogo.png" alt="" /> -->
      <img src="@/assets/images/logo.png" alt="">

      养老平台
    </div>
    <el-menu
      v-if="activeMenu"
      :default-active="tabName"
      class="el-menu-vertical-demo"
      background-color="#191a23"
      text-color="#fff"
      active-text-color="#fff"
      unique-opened
      @open="handleOpen"
      @close="handleClose"
    >
      <!-- 遍历一级菜单 -->
      <div v-for="(oneItem, oneIndex) in newMenuList" :key="oneIndex">
        <el-menu-item v-if="!oneItem.children" v-permission="oneItem.name" :index="oneItem.name" @click="goToPage(oneItem)">
          <i :class="oneItem.icon" />
          <span>{{ oneItem.label }}</span>
        </el-menu-item>
        <el-submenu v-else v-permission="oneItem.name" :index="oneItem.name">
          <template slot="title">
            <i :class="oneItem.icon" />
            <span>{{ oneItem.label }}</span>
          </template>

          <!-- 遍历二级菜单 -->
          <div v-for="(twoItem, twoIndex) in oneItem.children" :key="twoIndex">
            <el-menu-item v-if="!twoItem.children" v-permission="twoItem.name" :index="twoItem.name" @click="goToPage(twoItem)">
              <i :class="twoItem.icon" />
              <span>{{ twoItem.label }}</span>
            </el-menu-item>
            <el-submenu v-else v-permission="twoItem.name" :index="twoItem.name">
              <template slot="title">
                <i :class="twoItem.icon" />
                <span>{{ twoItem.label }}</span>
              </template>

              <!--遍历三级菜单-->
              <div v-for="(threeItem, threeIndex) in twoItem.children" :key="threeIndex">
                <el-menu-item v-permission="threeItem.name" :index="threeItem.name" @click="goToPage(threeItem)">
                  <i :class="threeItem.icon" />
                  {{ threeItem.label }}
                </el-menu-item>
              </div>
            </el-submenu>
          </div>
        </el-submenu>
      </div>
    </el-menu>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { menu } from '@/utils/menu'
export default {
  data() {
    return {
    }
  },
  computed: {
    ...mapState(['isCollapse', 'activeMenu', 'tabName']),
    newMenuList() {
      if (sessionStorage.menu === '0') {
        return menu.menuAll || []
      }
      if (sessionStorage.menu === '1') {
        return menu.menuOne || []
      }
      if (sessionStorage.menu === '2') {
        return menu.menuTwo || []
      }
      if (sessionStorage.menu === '3') {
        return menu.menuThree || []
      }
      if (sessionStorage.menu === '4') {
        return menu.menuFour || []
      }
      if (sessionStorage.menu === '5') {
        return menu.menuFive || []
      }
      if (sessionStorage.menu === '6') {
        return menu.menuSix || []
      }
      if (sessionStorage.menu === '7') {
        return menu.menuSeven || []
      }
      if (sessionStorage.menu === '8') {
        return menu.menuEight || []
      }
      if (sessionStorage.menu === '9') {
        return menu.menuNine || []
      }
      if (sessionStorage.menu === '10') {
        return menu.menuTen || []
      }
      return menu.menuAll
    }
  },
  created() {
  },
  methods: {
    ...mapMutations(['goToPage', 'removeTab']),
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    }
    // goToPage(item) {
    //   this.$store.state.activeMenu = item;
    //   localStorage.activeMenu = JSON.stringify(item)
    //   this.$router.push({
    //     name: item.name
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100%;
  color: #fff;
  background: #191a23;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: auto;
  .side-title {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #101117;
    background: #191a23;
    height: 63px;
    line-height: 63px;
    font-size: 18px;
    overflow: hidden;
    img {
      width: 30px;
      height: 30px;
      margin-right: 8px;
    }
  }
  .el-menu {
    height: 100%;
    overflow: auto;
    border: none;
    .el-menu-item.is-active {
      background-color: #2d8cf0 !important;
    }
    i {
      color: #fff;
      margin-right: 10px;
    }
  }
}
</style>
