<template>
  <!-- 导航栏（头像） -->
  <div class="header">
    <i class="el-icon-s-operation" @click="showMenu"></i>
    <!-- <h1>莲湖区智慧养老信息管理平台</h1> -->
    <!-- <h1>泽祥智慧养老信息管理平台</h1> -->
    <h1>{{ mainPageTitle }}</h1>
    <div class="date-wrapper">{{ nowDate }}</div>
    <div class="login_out">
      <el-dropdown class="avatar-container" trigger="click" @command="handleTrigger">
        <div class="avatar-wrapper">
          <div class="user-info">
            <!-- <img :src="src" alt="" /> -->
            <img :src="imgSrc" alt="" />

            <p class="user-name">{{ displayName }}</p>
          </div>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="changePassword">
            <span>
              <i class="el-icon-key"></i>
              修改密码
            </span>
          </el-dropdown-item>
          <el-dropdown-item command="goBackHome">
            <span>
              <i class="el-icon-s-home"></i>
              返回首页
            </span>
          </el-dropdown-item>
          <el-dropdown-item command="logout">
            <span>
              <i class="el-icon-switch-button"></i>
              退出系统
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" :visible.sync="visible" width="30%" :close-on-click-modal="false">
      <el-form :model="form" ref="form" label-width="70px" :rules="rules">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="form.oldPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form.newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <div style="text-align:center;">
            <el-button type="primary" @click="submitForm('form')">确定</el-button>
            <el-button @click="resetForm('form')">重置</el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Breadcrumb from '../../components/Breadcrumb'

export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      rules: {
        oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
      },
      nowDate: '',
      timer: null,
      visible: false,
      displayName: '',
      mainPageTitle: '智慧养老信息管理平台',
      form: {
        oldPassword: '',
        newPassword: '',
      },
      imgBase: '',
      imgSrc: '',
      src: '@/assets/images/avatar2x.png'
    }
  },
  computed: {
    ...mapGetters(['userInfos'])
  },
  created() {
    this.getUserInfo()
  },
  mounted() {
    this.timer = setInterval(this.updateTime, 1000)
  },
  beforeDestory() {
    this.timer.clear()
  },
  methods: {
    ...mapMutations(['goToPage']),
    ...mapActions(['userChangeMyPwd', 'loginUserInfo', 'downloadImg', 'getPhoto','logoutByClassbao']),
    getUserInfo() {
      let server = 'loginUserInfo'
      this[server]().then((res) => {
        if (res.code == 200) {
          console.log('cccvv', res.data)
          this.$store.commit('mUserInfo', res.data)
          this.displayName = res.data.displayName
          this.mainPageTitle = res.data.mainPageTitle
          this.getPhoto().then((res) => {
            if (res.code == 200) {
              this.imgSrc = res.data.content
            }
          })
          //  let server = 'downloadImg'
          //  let param ={
          //   fileId:res.data.photo
          //  }
          //   this[server](param).then((res) => {
          //     if(res.code==200){
          //       this.imgSrc =res.data.content
          //     }else{
          //       console.log('sleseaaa');
          //       this.getPhoto().then(res=>{
          //         this.imgSrc =res.data.content
          //       })
          //     }


          //   })
        }
      })
      //  let server = 'loginUserInfo'

    },
    showMenu() {
      this.$store.state.isCollapse = !this.$store.state.isCollapse
    },
    updateTime() {
      let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      this.nowDate = this.$dayjs().format('YYYY年MM月DD日') + ' ' + weeks[this.$dayjs().day()] + ' ' + this.$dayjs().format('HH:mm:ss')
    },
    handleTrigger(command) {
      if (command === 'changePassword') {
        this.visible = true
      } else if (command === 'logout') {
           //后端也要清空redis
        this.logoutByClassbao().then(e=>{       
          localStorage.clear()
          sessionStorage.clear()
          this.$router.replace({ name: 'Login' })
        })
      } else if (command === 'goBackHome') {
        this.goToPage({
          name: 'HomePage',
        })
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.userChangeMyPwd({
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword,
          }).then((res) => {
            if (res.code == 200) {
              this.$XModal.message({
                message: '操作成功',
                status: 'success',
              })
              this.visible = false
              setTimeout(() => {
                localStorage.clear()
                sessionStorage.clear()
          
               
              }, 1000)
            }
          })
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  .el-icon-s-operation {
    font-size: 30px;
    margin-right: 12px;
  }

  h1 {
    flex: 1;
    font-size: 30px;
  }

  .date-wrapper {
    padding: 0 20px;
    font-size: 17px;
    color: #606266;
  }

  .login_out {
    display: flex;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 20px;

    .user-info {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 32px;
        height: 32px;
        margin-right: 8px;
      }
    }

    .user-name:hover {
      color: #ff6c60;
    }

    .fa-user {
      color: #ff6c60;
      margin-right: 8px;
      font-size: 20px;
    }

    span {
      // float: left;
      height: 32px;
      line-height: 32px;
      margin-right: 10px;
    }

    i {
      // float: left;
      font-size: 32px;
      cursor: pointer;
    }
  }
}
</style>
<style>
.el-dropdown-menu__item:hover {
  background-color: red;
}
</style>
